import { connect } from 'react-redux';
import CourseNav from './course_nav';
import { logout } from '../../actions/session_actions';
import { fetchUser } from '../../actions/user_actions';

const mapStateToProps = (state) => {
  return {
    loggedIn: state.session.isAuthenticated,
    currentUser: state.session.user,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (id) => dispatch(fetchUser(id)),
    logout: user => dispatch(logout(user)),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseNav);