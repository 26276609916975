import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import Intro1 from '../../assets/intro6.png'
import Intro2 from '../../assets/2.png'
import NavBarContainer from '../nav/navbar_container'
import Logo from '../../assets/cosmetinkLogo.png'
import Logo1 from '../../assets/costranslogo.png'
import Logo2 from '../../assets/costranslogo2.png'


export default function MainPage(props) {

  const [open, setOpen] = useState(null)

  function setOpenSection(num) {
    if (open === num) {
      setOpen(null)
    } else {
      setOpen(num)
    }
  }
  

    return (
      <div className="main-body fade-in">
        <div className="main-page">
          
        <div className="landing-page">
          <br/>
          <br/>
          <br/>

                    <div className="entry-text fade-in">
                    <div className="entry-text-o down-60">The Artists' Platform.</div>
        

                    <div className=""> 
                    <div className="logo-layer-1">
                      <img src={Logo2}></img>
                    </div>
                    <div className="logo-layer-2">
                      <img src={Logo1}></img>
                    </div>
                    </div>
                    <div className="button-background">
                    </div>
                    <Link className="btnlogin" to='/login'>
                    <button className="get-started2 moving-gradient"><span className="btn-text">Log In</span></button>
                    </Link>
                    </div>
        </div>
          
         

          

<div className="hidden">
        <div onClick={() => setOpenSection(2)} className="drop">
        Send Your Before & After Photos
        <span className="add">+</span>
        </div>
        <div className={open === 2 ? "drop light" : "hidden"}>
        Include your name and the client's name in your subject line and send to: <a href="mailto:cosmetinkphotos@gmail.com">cosmetinkphotos@gmail.com</a>
        </div>

        <div onClick={() => setOpenSection(3)}  className="drop">
        Access Your 1099 Payroll Information
        <span className="add">+</span>
        </div>
        <div className={open === 3 ? "drop light" : "hidden"}>
        Head over to your square payroll account: <a href="https://squareup.com/help/us/en/article/5537-navigate-your-square-payroll-employee-dashboard">squareup.com</a>
        </div>



        <br/>
        <br/>
        © 2021 COSMETINK, COSMETINK ACADEMY
        <br/> All rights reserved.
        </div>
          </div>

          </div>
    );
    }