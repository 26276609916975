import React, {useState, useEffect} from 'react';


export default function Inventory(props) {

    const {} = props    
    const [brand, setBrand] = useState(null)
    const [productName, setProductName] = useState(null)
    const [size, setSize] = useState(null)
    const [cost, setCost] = useState(null)
    const [qty, setQty] = useState(null)




    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
     
    }, [])


    return (
        <>
        <div className={"wht"}>
           INVENTORY
        <div className='categories-row'>
        <div className='add-box'>
            <div className='add-item'>
                Add New Item
            </div>
            <div className='add-item plus'>
                +
            </div>

            <div className='add-new-form'>
            <form>
                <input onChange={(e) => setBrand(e.currentTarget.value)}  placeholder='Brand Name' type="textbox">
                </input>
                <input onChange={(e) => setProductName(e.currentTarget.value)}  placeholder='Product Name' type="textbox">
                </input>
                <input onChange={(e) => setSize(e.currentTarget.value)}  placeholder='Size (if for resale) ex. 15mL' type="textbox">
                </input>
                <input onChange={(e) => setCost(e.currentTarget.value)}  placeholder='Cost (if for resale) ex. enter 45 for $45.00' type="textbox">
                </input>
                <input onChange={(e) => setQty(e.currentTarget.value)} placeholder='Quantity' type="textbox">
                </input>
                <input className='submit' value="Add Item" type="submit">
                </input>
            </form>
            </div>
        </div>
        </div>
        </div>
        </>
    )
}