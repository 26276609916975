import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import samplePDF from '../../assets/CosmetinkAcademyPDF_2021.pdf';
import Footer from './footer'
import { Document, Page, pdfjs } from "react-pdf";
import Section from '../sections/section';
import ShapingWorksheets from '../../assets/BrowShapes.pdf'
import image from '../../assets/Header4.png'
import image2 from '../../assets/header2.png'
import { fetchUser } from '../../util/session_api_util';
import SubmissionSection from '../sections/submission_section';
import register_container from '../register/register_container';
import Register from '../register/register_container';
import TestCourse from '../course/test_course_container'
import CourseNav from './course_nav_container';

import Logo from '../../assets/cosmetinkLogo.png'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function Dashboard(props){

    const {logout, currentUser, user, fetchUser,
        fetchCourseMaterial,
           updateUserMicrobladingSessionsCompleted,
            updated,
            fetchAvailableCourses,
            availableCourses,
            fetchUserCourses,
            userCourses,
            newCourse,
            sections } = props
    const [display, setDisplay] = useState(null)
    const [pages, setPages] = useState([])
    const [check, setCheck] = useState(user ? user.sectionsCompleted : [])
    const [submissionSection, setSubmissionSection] = useState(null)
    const [register, setRegister] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [profile, setProfile] = useState(null)

    function openProfile() {
        profile ? setProfile(null) : setProfile(true)
    }

    useEffect(() => {
        if (!sections)
       fetchCourseMaterial("6050f5fe22fecae4439b8e1a")
    }, [sections])

    function openPage(key) {
        if (key) {
        setDisplay(key)
        } else {
            setDisplay(null)
        }
    }

    return (
        
        sections ? 
        <div className="PMU-details c">
        < CourseNav />
        <div className="PMU-detail-page c">
        <Footer selected={3} />
        <div className="slide-in-bottom">
         <h1 className="entry-text-o lower-case c">
             Microblading Foundations
         </h1>
         <br/>
        <div className="p700">
        {Object.keys(sections).map((key) => {
            return (
                <div className="fifty">
                <div onClick={() => openPage(key)}  className="course-section wht">
                <img className="course-back-image" src={`${sections[key].imageURL}`}/>
                <div className="course-section-title">{sections[key].title}</div>
                </div>
                <div className={display === key ? "open-section" : "hidden-down"}>
                <h1 className="crse-title">
                {sections[key].title}
                </h1>
                <div className="p600">
                <div className="text-box">
                {sections[key].videoURL ? 
                <video className="course" width="100%" poster={sections[key].poster} controls src={sections[key].videoURL} paused={display !== key} type="video/mp4" />  : null }

                {sections[key].prelude ? 
                <p className="prelude">
                    {sections[key].prelude}
                </p> : null }
                {sections[key].header1 ? 
                <h1 className="crse">
                    {sections[key].header1}
                </h1> : null }
                {sections[key].text1 ? 
                <p style={{ whiteSpace: 'pre-wrap' }}>
                    {sections[key].text1.replace(/\\n/g, '\n')}
                </p> : null }
                {sections[key].image1 ? 
                <img className="inside-text-box" src={sections[key].image1}>
                </img> : null }

                {sections[key].header2 ? 
                <h2 className="crse">
                    {sections[key].header2}
                </h2> : null }
                {sections[key].text2 ? 
                <p style={{ whiteSpace: 'pre-wrap' }}> 
                    {sections[key].text2.replace(/\\n/g, '\n')}
                </p> : null }
                {sections[key].image2 ? 
                <img className="inside-text-box" src={sections[key].image2}>
                </img> : null }

                {sections[key].header3 ? 
                <h2 className="crse">
                    {sections[key].header3}
                </h2> : null }
                {sections[key].text3 ? 
                <p style={{ whiteSpace: 'pre-wrap' }}>
                    {sections[key].text3.replace(/\\n/g, '\n')}
                </p> : null }
                {sections[key].image3 ? 
                <img className="inside-text-box" src={sections[key].image3}>
                </img> : null }

                {sections[key].header4 ? 
                <h2 className="crse">
                    {sections[key].header4}
                </h2> : null }
                {sections[key].text4 ? 
                <p style={{ whiteSpace: 'pre-wrap' }}>
                    {sections[key].text4 ? sections[key].text4.replace(/\\n/g, '\n') : null}
                </p> : null }
                {sections[key].image4 ? 
                <img className="inside-text-box" src={sections[key].image4}>
                </img> : null }

                {sections[key].header5 ? 
                <h2 className="crse">
                    {sections[key].header5}
                </h2> : null }
                {sections[key].text5 ? 
                <p style={{ whiteSpace: 'pre-wrap' }}>
                    {sections[key].text5 ? sections[key].text5.replace(/\\n/g, '\n') : null}
                </p> : null }

                {sections[key].header6 ? 
                <h2 className="crse">
                    {sections[key].header6}
                </h2> : null }
                {sections[key].text6 ? 
                <p style={{ whiteSpace: 'pre-wrap' }}>
                    {sections[key].text6 ? sections[key].text6.replace(/\\n/g, '\n') : null}
                </p> : null }

                {sections[key].header7 ? 
                <h2 className="crse">
                    {sections[key].header7}
                </h2> : null }
                {sections[key].text7 ? 
                <p style={{ whiteSpace: 'pre-wrap' }}>
                    {sections[key].text7 ? sections[key].text7.replace(/\\n/g, '\n') : null}
                </p> : null }

                {sections[key].Video1 ? 
                        <>
                                <h2 className="crse">
                                {sections[key].Video1Title}
                                </h2>
                                <video className="course" width="100%" poster={sections[key].poster} controls src={sections[key].Video1} paused={display !== key} type="video/mp4" /> 
                        </>  : null}
                                
                {sections[key].Video2 ? 
                                <>
                                <h2 className="crse">
                                {sections[key].Video2Title}
                                </h2>
                                <video className="course" width="100%" poster={sections[key].poster} controls src={sections[key].Video2} paused={display !== key} type="video/mp4" /> 
                                </> : null}

                {sections[key].Video3 ? 
                                <>
                                <h2 className="crse">
                                    {sections[key].Video3Title}
                                </h2>
                                <video className="course" width="100%" poster={sections[key].poster} controls src={sections[key].Video3} paused={display !== key} type="video/mp4" /> 
                                </> : null}

                {sections[key].closing ? 
                <p style={{ whiteSpace: 'pre-wrap' }} className="closing"> 
                    {sections[key].closing ? sections[key].closing.replace(/\\n/g, '\n') : null}
                </p> : null }

                {sections[key].link1 ? 
                <>
                <h2 className="crse">
                    Additional Resources
                </h2>
                <p>
                    <a href={sections[key].link1} target='_blank'><i class="fab fa-youtube"></i> Click here to view video</a>
                </p>
                
                </> : null}

                {sections[key].assignmentLink ? 
                <>
                <h2 className="crse">
                    Practice Assignment
                </h2>
                <p>
                    {sections[key].assignmentDesc}
                    <br/><br/>
                    <a href={sections[key].assignmentLink} target='_blank'><i class="fal fa-file-pdf"></i> Click here to view assignment</a>
                </p>
                
                </> : null}

                {sections[key].bloodborne ? 
                <div className="bloodborne">
                <h2 className="crse">
                    Assignment: Bloodborne Pathogen Course & Certification
                </h2>
                <p>
                In order to work on your live model, you MUST complete a bloodborne pathogen training.  THERE IS NO EXCEPTION.  Please use this link to visit "Protrainings" to take the course and get your bloodborne certification.  Once you have completed it and obtained your certification, please send it to us in the "submissions" section of this course.  This will take several hours, so be sure to allow yourself enough time. You can start and come back any time and as much as you need until you finish it. 
                Be sure to select the "Body Art" option when enrolling.
                <br/><br/>
                <a href={sections[key].bloodborne}><i class="fal fa-users-class"></i> Click Here for the Bloodborne Pathogen Course & Certification</a>
                </p>
                </div> : null}
                </div>
                </div>

   
                </div>
                <div onClick={() => openPage(null)} className={display ? "full-overlay" : "hidden"}>
                <i class="far fa-times-circle"></i>
                </div>
                </div>
             
                
            )
        
        })}</div>

        </div></div></div>
        : 
        <div className="PMU-details">
              <CourseNav/>
              <Footer selected={3} />
        </div>
        
    )
    }