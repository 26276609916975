import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Sean from '../../assets/sean_face.png'
import { Ring } from 'react-spinners-css';

export default function Create(props) {

    const [newUser, setNewUser] = useState(false)
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [loggingIn, setLoggingIn] = useState(false)
    const [nextForCreate, setNextForCreate] = useState(true)
    const {login, signup, sessionErrors} = props

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    useEffect(() => {
        if (sessionErrors) {
            setLoggingIn(false)
        }
    }, [sessionErrors])

    function handleCreateUser(e) {
        e.preventDefault();

        let user = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            password: password,
            password2: password2
        };


        let userlogin = {
            email: email,
            password: password
        }
       
        if (signup(user)) {
            setLoggingIn(true);
            setTimeout(() => {
                login(userlogin); 
            }, 2000);
        }
    
        
    }



        return (
            <div className="full-body">
                <div className="login-header">
                <Link to ="/"><div className="nav-btn"><i class="far fa-chevron-left"></i></div></Link>
                <Link to="/login"><div className="login-btn">LOG IN</div></Link>
                    <div className="body">
                    <div className="ai-talk">
                    <img className="face" src={Sean}></img>
                    <div className="ai-text-bx">
                    <div className="ai-gradient">Hey! I'm Sean.</div>
                    <div className="bld wht">Ready to get started? <br/> I just need some information and you'll be all set.</div>
                    </div>
                    </div>
                    </div>
                
                <div className="login-form-container">
                <div className={"login-form"}>
                    
                 
                        <form onSubmit={handleCreateUser}>
                        <input onChange={(e) => setFirstName(e.currentTarget.value)} type="text" placeholder="First Name"></input>
                        <input onChange={(e) => setLastName(e.currentTarget.value)} type="text" placeholder="Last Name"></input>
                        <input onChange={(e) => setEmail(e.currentTarget.value)}  type="text" placeholder="Email"></input>
                        <input onChange={(e) => setPassword(e.currentTarget.value)} type="password" placeholder="Password"></input>
                        <input onChange={(e) => setPassword2(e.currentTarget.value)} type="password" placeholder="Verify Password"></input>
                        {sessionErrors ? 
                        <div>{Object.keys(sessionErrors).map((key) => {
                            return (
                                <div className="error">{sessionErrors[key]}</div>
                            )
                        }) 
                        }</div> : null }
                        <div className="disclaimer">By creating an account, you agree to our <br/> Terms of Service and have read and understood our <br/> Privacy Policy.</div>
                        <input className={password ? "submit" : "submit-no"} value="Let's Do This" type="submit"></input>
                        <div className={loggingIn ? "connecting" : "hidden"}>Connecting...</div>
                        </form>
                </div>
                </div>
         
            </div>

                <div className={loggingIn ? "overlay" : "hidden"}>
                <div className={loggingIn ? "ring" : "hidden"}>
                    <Ring className="ring" color="#fff" size={30} />
                </div> 
                </div>

            </div>
        )
    }