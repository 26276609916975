import { connect } from 'react-redux';
import Dashboard from './dashboard';
import { logout } from '../../actions/session_actions';
import { fetchUser, updateUserMicrobladingSessionsCompleted } from '../../actions/user_actions';
import {fetchAvailableCourses, fetchUserCourses, fetchCourseMaterial} from '../../actions/course_actions'

const mapStateToProps = (state) => {
  return {
    loggedIn: state.session.isAuthenticated,
    currentUser: state.session.user,
    user: state.user.user,
    updated: state.user.updated,
    availableCourses: state.courses.availableCourses,
    userCourses: state.courses.userCourses,
    newCourse: state.courses.newCourse,
    sections: state.courses.sections
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (id) => dispatch(fetchUser(id)),
    fetchAvailableCourses: () => dispatch(fetchAvailableCourses()),
    fetchUserCourses: (user_id) => dispatch(fetchUserCourses(user_id)),
    fetchCourseMaterial: (course_id) => dispatch(fetchCourseMaterial(course_id)),
    logout: user => dispatch(logout(user)),
    updateUserMicrobladingSessionsCompleted: (id, update) => dispatch(updateUserMicrobladingSessionsCompleted(id, update)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);