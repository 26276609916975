import { connect } from 'react-redux';
import { login } from '../../actions/session_actions';
import Create from './create';
import { signup } from '../../actions/session_actions';

const mapStateToProps = (state) => {
  return {
    signedIn: state.session.isSignedIn,
    sessionErrors: state.errors.session
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: user => dispatch(login(user)),
    signup: user => dispatch(signup(user))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Create);