import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Section from '../sections/section';
import { pdfjs } from "react-pdf";
import samplePDF from '../../assets/CosmetinkAcademyPDF_2021.pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CourseSection(props){

    const {chapter, chapter_id, sections, fetchCourseMaterial, view, setView} = props
    const [showPDF, setShowPDF] = useState(false)
    const [showVideo, setShowVideo] = useState(false)

    function setOpen() {
        view === chapter_id ? setView(null) : setView(chapter_id)
    }

    function setPDF() {
        showPDF ? setShowPDF(false) : setShowPDF(true)
    }

    function setVideo() {
        showVideo ? setShowVideo(false) : setShowVideo(true)
    }

    useEffect(() => {
        if (view == chapter_id)
       fetchCourseMaterial(chapter_id)
    }, [view])

    return (
        <>
        <div onClick={setOpen} className="course-header">
            {chapter} <i className={view === chapter_id ? "far fa-chevron-down" : "far fa-chevron-right"}></i>
        </div>

        <div className={view == chapter_id ? "course-detail" : "hidden"}>
        {sections ? Object.keys(sections).map((key) => {
            return <>
                    <div className="course-list-item">
                    <div onClick={setVideo} className="section-title">{sections[key].title}<i className={showVideo ? `far fa-minus` : `far fa-plus`}></i></div>
                    {sections[key].videoURL && showVideo ? 
                    <>
                    <br/>
                    <video className="course" width="100%" poster={`https://cosacademyvideos.s3.us-east-2.amazonaws.com/acadvid1.png`} controls playsInline src={sections[key].videoURL} type="video/mp4" /> </> : null }
                    </div>

                     <div className="course-list-item">
                    <div onClick={setPDF} className="section-title">Readings<i className={showPDF ? `far fa-minus` : `far fa-plus`}></i></div>
                    {showPDF ? 
                    <>
                    <br/>
                    <Section samplePDF={samplePDF} pages={sections[key].pdfPages} /> </>: null }
                    </div>

                    </>
        })
        : null }
        </div>
        </>
        
    )
    }