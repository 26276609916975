import React, {useState, useEffect} from 'react';
import { uploadFile } from 'react-s3';
import { Ring } from 'react-spinners-css';
 
export default function UploadS3(props) {

    const { setURL } = props
    const [file, setFile] = useState(null)
    
          /**
       * {
       *   Response: {
       *     bucket: "your-bucket-name",
       *     key: "photos/image.jpg",
       *     location: "https://your-bucket.s3.amazonaws.com/photos/image.jpg"
       *   }
       * }
       */

    useEffect(() => {
        if (file)
         {
            uploadFileToS3()
          }
    }, [file])
       

    function uploadFileToS3() {


        const config = {
            bucketName: 'owrld',
            region: 'us-east-2',
            accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        }
        
        uploadFile(file, config)
            .then((data) => setURL(data.location)
                )
            .catch(err => console.error(err))
    }

    return (
        <>
        {!file ? 
        <label class="custom-file-upload">
        <input onChange={(e) => setFile(e.target.files[0])} accept=".png, .jpg, .jpeg, .pdf" className="inputfile" type="file"/>
        <div className="add-input"><i class="far fa-plus-circle"></i></div>
        </label>
        : <label class="custom-file-upload">
        <div className="ring">
        <Ring color="#fff" size={30} />
        </div>
        </label>
        }
        </>
    )
}