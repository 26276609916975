import { connect } from 'react-redux';
import Register from './register';
import { logout } from '../../actions/session_actions';
import {fetchAvailableCourses, fetchUserCourses} from '../../actions/course_actions'

const mapStateToProps = (state) => {
  return {
    loggedIn: state.session.isAuthenticated,
    currentUser: state.session.user,
    availableCourses: state.courses.availableCourses,
    userCourses: state.courses.userCourses
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAvailableCourses: () => dispatch(fetchAvailableCourses()),
    fetchUserCourses: (user_id) => dispatch(fetchUserCourses(user_id)),
    logout: user => dispatch(logout(user)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);