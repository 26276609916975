import { connect } from 'react-redux';
import Registration from './registration';
import { logout } from '../../actions/session_actions';
import { fetchAvailableCourses } from '../../actions/course_actions';

const mapStateToProps = (state) => {
  return {
    courses: state.courses.availableCourses
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAvailableCourses: () => dispatch(fetchAvailableCourses())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration);