import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Footer from './footer';
import CourseNav from './course_nav_container';
import { Heart, Ring } from 'react-spinners-css';
import Logo1 from '../../assets/costranslogo.png'
import Logo2 from '../../assets/costranslogo2.png'
import BookingPage from './booking_page_container';

export default function CreateTask(props) {

    const {currentUser, close, postTask} = props    

    const [body, setBody] = useState(null)

    function handleSubmit(e) {
        e.preventDefault();
    
        let task = {
          body: body,
          submittedBy: currentUser.first_name
        };

        postTask(task)
        close()
    }

    function setTaskBody(e) {
        setBody(e)
    }
   

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
     
    }, [])


    return (
        <>
        <div className={"task-page slide-in-bottom"}>
            <div className=''>
            <div onClick={close} className="scheduled-appt-title wht abs-nav">close</div>

                <div className='add-task-box'>
                <div className="entry-text-p l pad-10 moving-gradient task-title">Adding a Task...</div>
                <form onSubmit={handleSubmit}>
                <input className="text-box c" onChange={(e) => setTaskBody(e.currentTarget.value)}  type="textbox" placeholder="Enter details for the task here...."></input>
                <input className={body  ? "submit" : "submit-no"} value={"Add Task"} type="submit"></input>
                </form> 
                </div>

            </div>
        </div>
        </>
    )
}