import { connect } from 'react-redux';
import BookingPage from './booking_page';
import { fetchAvailableDates, fetchAvailableTimes, postAppointment,
fetchAppointments3, fetchArtist } from '../../actions/user_actions'

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.user,
    artist: state.artist.artist,
    availability: state.user.availability,
    times: state.user.times,
    newAppointment: state.user.newappointment,
    monthlyAppointments: state.user.monthlyAppointments,
    monthlyAppointments2: state.user.monthlyAppointments2
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAvailableDates: (calendarID, apptid, month) => dispatch(fetchAvailableDates(calendarID, apptid, month)),
        fetchAvailableTimes: (calendarID, apptid, date) => dispatch(fetchAvailableTimes(calendarID, apptid, date)),
        postAppointment: (apptDetails) => dispatch(postAppointment(apptDetails)),
        fetchAppointments3: (calendarID, minDate, maxDate) => dispatch(fetchAppointments3(calendarID, minDate, maxDate)),
        fetchArtist: (artist) => dispatch(fetchArtist(artist))
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingPage);