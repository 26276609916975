import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Footer from './footer'
import Logo from '../../assets/cosmetinkLogo.png'
import CourseNav from './course_nav_container';

export default function SearchBar(props) {
    

    const {fetchClients, clients, fetchClientAppts, clientAppts} = props
    const [search, setSearch] = useState(null)
    const [clientPage, setClientPage] = useState(false)
    const [selectedClient, setSelectedClient] = useState(null)


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function getClientList(e) {
        search ? setSearch(false) : setSearch(true)

        if (search)
        fetchClients(e)
    }

    function openClientPage(firstName, lastName) {
        clientPage ? setClientPage(false) : setClientPage(true)
    
        fetchClientAppts(firstName, lastName)
        setSelectedClient(`${firstName} ${lastName}`)
    
    }

    function closeAll() {
        setSelectedClient(null)
        openClientPage()
        setSearch(false)
    }




    return (
        <div className='searchbar-container wht'>
       <input className='searchbar' onChange={(e) => getClientList(e.currentTarget.value)}  type="textbox" placeholder="Search Clients here....">
       </input>
       {clients && search ? 
       <div className='searchbar-results'>
        {clients && Object.keys(clients).map((key) => {
            return (
                <div onClick={() => openClientPage(clients[key].firstName, clients[key].lastName)} className='searchbar-result'>
                  
                    {clients[key].firstName} { clients[key].lastName}
                </div>
            )
           
        })}
       </div> : null }

       {clientPage && clientAppts && selectedClient ? 
       <div className='client-page'>
        <div onClick={closeAll} className='close-all wht'>CLOSE</div>
        <div className='client-page-name wht'>
       {selectedClient}
        </div>
        <div className='client-page-inside'>
        {
            Object.keys(clientAppts).map((key) => {
                return (
                    <div className='client-appt-box'>
                        <div className='client-appt-type date'>
                            {clientAppts[key].date}
                        </div>
                        <div className='client-appt-type'>
                            {clientAppts[key].type}
                        </div>
                        <div className='client-appt-type time'>
                            {clientAppts[key].time}
                        </div>
             
            
                        <div className='client-appt-type notes'>
                            {clientAppts[key].notes}
                        </div>
                        {clientAppts[key].canceled ?
                        <div className='client-appt-type canceled'>
                           Canceled   
                        </div> : null}
                     
                    </div>
                )
    
            })
        }   </div>

       </div> : null}
        </div>
    )
}