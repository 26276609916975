import { RECEIVE_TASKS, RECEIVE_NEW_TASK, RECEIVE_DELETED_TASK} from '../actions/task_actions';

const TasksReducer = (state = { tasks: undefined, newTask: undefined, deletedTask: undefined}, action) => {

Object.freeze(state);
let newState = Object.assign({}, state);
switch(action.type) {
 case RECEIVE_TASKS:
   newState.tasks = action.tasks.data;
   return newState;
case RECEIVE_NEW_TASK:
    newState.newTask = action.newTask.data;
    return newState;
case RECEIVE_DELETED_TASK:
        newState.deletedTask = action.deletedTask.data;
        return newState;
 default:
   return state;
}
};

export default TasksReducer;