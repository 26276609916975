import React, { useEffect, useState } from 'react';
import {PaymentRequestButtonElement, useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {Redirect} from 'react-router-dom';
import { Ring } from 'react-spinners-css';

import CardSection from './card_section';
import { signupCourse } from '../../util/course_api_util';

export default function CheckoutForm(props) {

  const { intent, history, currentUser, setProcessing, courseName, courseID } = props
    
  const stripe = useStripe();
  const elements = useElements();
  const [succeeded, setSucceeded] = useState(false)
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{paymentRequest}} />
  }


  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true)

    const result = await stripe.confirmCardPayment(intent.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${currentUser.first_name + " " + currentUser.last_name}`,
          email: currentUser.email,
          address: {
            city: null,
            country: null,
            line1: null,
            line2: null,
            postal_code: null,
            state: null
          },
      

        },
      },
      receipt_email: currentUser.email
    });

    const courseData = {
        name: courseName,
        cost: 995,
        user_id: currentUser.id,
        course_id: courseID
    }

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
          if (signupCourse(currentUser.id, courseData)) {
          setSucceeded(true) 
            window.location.reload()}
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (

    <>
    <form onSubmit={handleSubmit}>
      <CardSection />
      <br/><br/>
      <button disabled={!stripe} className="schedule-btn"><div className="schedule-inside">Confirm order</div></button>
    </form>
    </>
  );
}