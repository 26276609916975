import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Footer from './footer'
import Logo from '../../assets/cosmetinkLogo.png'
import CourseNav from './course_nav_container';

export default function Submissions(props) {

    const {fetchAssignments, assignments, currentUser} = props    
    const [open, setOpen] = useState(null)

    const [profile, setProfile] = useState(null)

    function openProfile() {
        profile ? setProfile(null) : setProfile(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!assignments) {
            fetchAssignments(`6050f5fe22fecae4439b8e1a`)
        }
    }, [assignments])

    function setOpenSection(key) {
        open !== key ? setOpen(key) : setOpen(null)
    }

    return (
        <>
        <div className="PMU-details wht-back">
        <CourseNav color={true} />
        <Footer selected={5} />
        <div className="PMU-detail-page wht-back">

        <div className="slide-in-bottom">

        <div className="p700">
        <div className="white-back">
        <div className="white-back">
        <div className="entry-text-p">Upload Photos.</div>
        </div>
        <div className="pad-20 font">


        Remember to submit photos of your before & afters of all of your appointments (including healed work photos) within 15 days of completion.
        <br/><br/>
        {currentUser ?
                <a href={`mailto:cosmetinkphotos@gmail.com?subject=${currentUser.first_name + ` ` + currentUser.last_name + `INSERT CLIENT NAME HERE`}&body=`} className="assignment-submission"><span className=""></span>Send Your Photos</a>
                    : null }
        </div>
    
  
        </div>
        </div>
        </div>
        </div>
        </div>
        </>
    )
}