import axios from 'axios';

export const fetchTasks = () => {
    return axios.get(`/api/tasks/`) }

export const postTask = (taskData) => {
    return axios.post(`/api/tasks/post`, taskData)
}

export const deleteTask = (task_id) => {
    return axios.delete(`/api/tasks/${task_id}`)
}


