import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Microblading from '../../assets/microheading.png'
import Checkout from '../payments/payment_container';
import CustomerInfo from '../customer/customer_info_container';
import CourseSection from './course_sections_container';

export default function TestCourse(props){

    const {setSelectedCourse, selectedCourse, fetchCourseMaterial, sections, fetchChapters, chapters } = props
    const [view, setView] = useState(null)

    useEffect(() => {
        if (!sections) {
            fetchCourseMaterial(selectedCourse._id)
        }
    }, [sections])


    return (
        <div className="slide-in-right">
        <div className="dash-main blk-pnk">

        <>
        <div onClick={() => setSelectedCourse(null)} className="nav-btn l wht">
            <i class="fa fa-chevron-left"></i> &nbsp; Back
        </div>
        <div className="dash inside-scroll">
 
        {sections ? Object.keys(sections).map((key) => {
            return (
            <CourseSection setView={setView} view={view} chapter={sections[key].title} chapter_id={sections[key]._id} />
            )
        }) : null }
        </div>

        </>
        </div>
        </div>
    )
    }