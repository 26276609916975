import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Microblading from '../../assets/microheading.png'
import BookingPage from './booking_page';


export default function Registration(props){

    const {fetchAvailableCourses, courses } = props
    const [register, setRegister] = useState(false)
    const [installment, setInstallment] = useState(false)
    

    useEffect(() => {
        fetchAvailableCourses()

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])


    function setRegisterPage() {
        register ? setRegister(false) : setRegister(true)
    }

    function setOpenInstallment() {
        installment ? setInstallment(false) : setInstallment(true)
    }

    return (
        <>
        <div className="slide-in-right">
        <div className="main-body l">

        <>
        <Link to="/" className="nav-btn l wht">
        <i class="fa fa-chevron-left"></i> &nbsp; Back
        </Link>
        
        {courses && !register ? Object.keys(courses).map((key) => {
        return (
        <>
        <div className="main-page-2 l">
       <img className="course-2" src={Microblading}></img>
       <br/>
       <div className="course-order-header">
           Cost
       </div>
       <div className="course-cost">
           ${courses[key].cost}.00
           <br/><br/>
           $600 NON-REFUNDABLE DEPOSIT required to register and reserve a spot
           <br/><br/>
           Remaining balance due by June 26 (first day of the course).
       </div>
       <br/><br/><br/>
       <div onClick={setRegisterPage} className="schedule-btn"><div className="schedule-inside">Register Now</div></div>
       <br/>
       <div className="course-order-header">
           Description
       </div>
       <div className="course-name">
           {courses[key].info}
       </div>

       <div className="course-order-header">
           Contact Us
       </div>
       
       <div className="course-name">
       Email us at <a href="mailto:cosmetinkbeauty@gmail.com">cosmetinkbeauty@gmail.com</a> if you have any questions or concerns.

       <br/>
       <br/>
       </div>
       </div>

        </>)}) : null}


        </>
        <br/>
        <br/>
        <br/>
        </div>

        </div>
                {
                    register ?
                    <BookingPage  setRegisterPage={setRegisterPage} calendarID={"11666635"}/>
                    : null
             }
             </>
    )
    }