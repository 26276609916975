import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Info from '../course/info';


export default function CustomerInfo(props){

    const { currentUser } = props


    useEffect(() => {
    }, [])

    return (
        <div className="dash-main">
 
        </div>
    )
    }