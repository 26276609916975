import { connect } from 'react-redux';
import Welcome from './welcome';
import { fetchAppointments } from '../../actions/user_actions'

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.user
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
    fetchAppointments: (calendarID, minDate, maxDate) => dispatch(fetchAppointments(calendarID, minDate, maxDate))
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Welcome);