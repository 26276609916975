import { connect } from 'react-redux';
import Course from './course_dash';
import { logout } from '../../actions/session_actions';
import { fetchUser, updateUserMicrobladingSessionsCompleted } from '../../actions/user_actions';

const mapStateToProps = (state) => {
  return {
    loggedIn: state.session.isAuthenticated,
    currentUser: state.session.user,
    user: state.user.user,
    updated: state.user.updated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (id) => dispatch(fetchUser(id)),
    logout: user => dispatch(logout(user)),
    updateUserMicrobladingSessionsCompleted: (id, update) => dispatch(updateUserMicrobladingSessionsCompleted(id, update)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Course);