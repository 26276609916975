import React, {useState, useEffect } from 'react';
import samplePDF from '../../assets/CosmetinkAcademyPDF_2021.pdf';
import { Document, Page, pdfjs } from "react-pdf";
import Section from '../sections/section';
import ShapingWorksheets from '../../assets/BrowShapes.pdf'
import image from '../../assets/Header4.png'
import image2 from '../../assets/header2.png'
import { fetchUser } from '../../util/session_api_util';
import SubmissionSection from '../sections/submission_section';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function Course(props){

    const {logout, currentUser, user, fetchUser,
           updateUserMicrobladingSessionsCompleted,
            updated } = props
    const [display, setDisplay] = useState(null)
    const [pages, setPages] = useState([])
    const [check, setCheck] = useState(user ? user.sectionsCompleted : [])
    const [submissionSection, setSubmissionSection] = useState(null)
    
    useEffect(() => {
        if (!user) {
        fetchUser(currentUser.id) }
    }, [user])

    useEffect(() => {
        if (updated) 
        fetchUser(currentUser.id)
    }, [updated])

    useEffect(() => {
        if (user)
        updateCheck()
    }, [user])

    useEffect(() => {
        if (display == 1) {
            setPages([1,2])
        } else if (display == 2) {
            setPages([3,4])
        } else if (display == 3) {
            setPages([8,9])
        } else if (display == 4) {
            setPages([10,11])
        } else if (display == 5) {
            setPages([12,13, 14, 15, 16])
        } else if (display == 6) {
            setPages([17,18, 19])
        } else if (display == 8) {
            setPages([21,22])
        } else if (display == 9) {
            setPages([23,24, 25, 26])
        }
    }, [display])

    function updateCompleted() {
        const update = {
            sectionsCompleted: check.concat(display)
        }
        updateUserMicrobladingSessionsCompleted(currentUser.id, update)
    }


    function updateCheck() {
        setCheck(user.sectionsCompleted)
    }

    return (
        <>
        Welcome to our course!




        <div className="hidden">
        <div className="dash-nav">
        <div className={display ? "hidden" : "login-btn blk"} onClick={() => logout()}>
            <i class="fas fa-user-circle"></i>
        </div>
        </div>

        <div className="dash-2">


        <div className={display ? "hidden" : ""}>
        <img className={"header fade-in"} src={image}></img>
        <div className={"dark-overlay"}></div>
        <div className="dashboard slide-in-bottom">

        <div className={ "course-container2" }>
        <div className="course-container-inside ">

            <div className="course-wrap">   
        <div className={"course-section"}>
            <div className="course-header top">
                Welcome
            </div>
                <div className={check.includes(1) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
                <div onClick={display === 1 ? () => setDisplay(null) : () => setDisplay(1)} className={check.includes(1) ? "course-page selected" : "course-page"}>
                <i class="fad fa-file-alt"></i> &nbsp; Introduction To Microblading
                </div>

   
        </div>

        <div className={"course-section"}>
            <div className="course-header">
                The Science & Art of Shaping 
            </div>
                <div className={check.includes(2) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
                <div onClick={() => setDisplay(2)} className={check.includes(2) ? "course-page selected" : "course-page"}>
                <i class="fad fa-file-alt"></i> &nbsp; Brow Shaping & The Golden Ratio
                </div>

                <div className="course-check pink-back">
                <i class="fad fa-check-square"></i>
                </div>
                
                <div onClick={() => setSubmissionSection(1)} className={"course-page pink-back"}>
                <i class="fas fa-pencil"></i> &nbsp; Brow Shaping Assignment
                </div>

            <div className="course-header">
                Developing the Perfect Stroke
            </div>

            <div className={check.includes(3) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
            <div onClick={() => setDisplay(3)} className={check.includes(3) ? "course-page selected" : "course-page"}>
                <i class="fad fa-file-alt"></i> &nbsp; Stroke Basics
            </div>

            <div className="course-check pink-back">
                <i class="fad fa-check-square"></i>
                </div>
                
                <div onClick={() => setSubmissionSection(1)} className={"course-page pink-back"}>
                <i class="fas fa-pencil"></i> &nbsp; Strokes Assignment
            </div>


         
                <div className={check.includes(3) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
                <div onClick={() => setDisplay(3)} className={check.includes(3) ? "course-page selected" : "course-page"}>
                <i class="fad fa-file-alt"></i> &nbsp; Pigment Filling & Depth
                </div>


                <div className="course-header">
                Client Relations & Interactions
                </div>
  
                <div className={check.includes(4) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
                <div onClick={() => setDisplay(4)} className={check.includes(4) ? "course-page selected" : "course-page"}>
                <i class="fad fa-file-alt"></i> &nbsp; Client Consultations
                </div>


                <div className={check.includes(5) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
                <div onClick={() => setDisplay(5)} className={check.includes(5) ? "course-page selected" : "course-page"}>
                <i class="fad fa-file-alt"></i> &nbsp; Skin Problems & Contraindications
                </div>


                <div className={check.includes(6) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
                <div onClick={() => setDisplay(6)} className={check.includes(6) ? "course-page selected" : "course-page"}>
                <i class="fad fa-file-alt"></i> &nbsp; Pathogens and WHMIS
                </div>


                <div className={check.includes(7) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
                <div className={"course-page"}>
                <i class="fad fa-download"></i> &nbsp; Review Questions Worksheet
                </div>

                <div className="course-header">
                Fundamentals of Microblading
                </div>

                <div className={check.includes(8) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
                <div onClick={() => setDisplay(8)} className={"course-page"}>
                <i class="fad fa-file-alt"></i> &nbsp; Color Theory
                </div>
 
                <div className={check.includes(9) ? "course-check green" : "course-check"}>
                <i class="fad fa-check-square"></i>
                </div>
                <div onClick={() => setDisplay(9)} className={"course-page"}>
                <i class="fad fa-file-alt"></i> &nbsp; The Procedure 
                </div>

                <div className="padding">

                </div>

            </div>
            </div> 
        </div>
        </div>

        </div>
        </div>

        <div className={submissionSection === 1 ? "submission fade-in" : "hidden"}>
        <SubmissionSection worksheets={ShapingWorksheets} setSubmissionSection={setSubmissionSection}/>
        </div>
        <div className={display ? "pdfViewer fade-in" : "hidden"}>
            <div onClick={() => setDisplay(null)} className="back-btn">
                <i class="fas fa-arrow-circle-left"></i>
            </div>
                <Section pages={pages} />
                <div onClick={check.includes(1) ? null : () => updateCompleted()} className={check.includes(1) ? "section-footer pink" : "section-footer"}>
                    {check.includes(1) ? "Completed" : "Mark Complete"}
                    <div className={check.includes(1) ? "pink check" : "check"}>
                        <i class="fas fa-check-square"></i>
                    </div>
                </div>
        </div>
        </div>
        </div>
        </>
    )
    }