import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Info from '../course/info';


export default function Register(props){

    const { fetchAvailableCourses,
            availableCourses,
            fetchUserCourses,
            userCourses,
            setRegister,
            currentUser } = props

    const [info, setInfo] = useState(false)
    const [chosenCourse, setChosenCourse] = useState(null)

    useEffect(() => {
        fetchAvailableCourses() 
        fetchUserCourses(currentUser.id)
    }, [])

    const alreadyTaken =
    userCourses ? 
    Object.keys(userCourses).map((key) => {
        return (
    userCourses[key].name
     )}) : null

     const available = 
     availableCourses ? 
     Object.keys(availableCourses).map((key) => {
         return (
     availableCourses[key].name
      )}) : null

    return (
        <div className="dash-main">
        <div className="slide-in-right">
     
        {!chosenCourse ? 
        <>
        {available && alreadyTaken && (available.count != alreadyTaken.count) ? 
        <>
        <div className="dash">
        <div className="entry-text-low cos-gradient">Available courses. </div> 
            {Object.keys(availableCourses).map((keys) => {
                if (!alreadyTaken.includes(availableCourses[keys].name))
                return (
             
                    <div onClick={() => setChosenCourse(availableCourses[keys])} className="course-list-item taken">
                    <div className="more-btn l wht">{ availableCourses[keys].name } <i class="fa fa-chevron-right"></i></div>
                    </div>

                )
                if (alreadyTaken.includes(availableCourses[keys].name))
                return (

                    <div onClick={() => setChosenCourse(availableCourses[keys])} className="course-list-item taken">
                    <div className="more-btn l wht">{ availableCourses[keys].name } <i class="fa fa-chevron-right"></i></div>
                    </div>
        
                )

            })}
            <br/><br/>
   
        <div onClick={() => setRegister(false)} className="more-btn l"><i class="fa fa-chevron-left"></i> &nbsp; Return to dashboard</div>

        </div>
        </>
        
        
        : 
        (available && alreadyTaken.length < 1 ) ? 
        <>
        <div className="dash">
        <div className="entry-text-low cos-gradient">Available courses. </div> 
            {Object.keys(availableCourses).map((keys) => {
                return (
   
                    <div onClick={() => setChosenCourse(availableCourses[keys])} className="course-list-item taken">
                    <div className="more-btn l wht">{ availableCourses[keys].name } <i class="fa fa-chevron-right"></i></div>
                    </div>
         
                )
            })}
            <br/><br/>
            <div onClick={() => setRegister(false)} className="more-btn l"><i class="fa fa-chevron-left"></i> &nbsp; Return to dashboard</div>
        </div>
        </> :
        <div className="section3">
        <div className="entry-text-low">
        <div className="cos-gradient">We're sorry! </div>

          There are no more
          <br/>
          available courses at this time.
        </div>
        <div className="lower-text">
        We are working
        <br/>
        hard to create more
        <br/> advanced courses,
        <br/> and we'll let you know
        <br/> when they're launching!
        <br/><br/>
        <div onClick={() => setRegister(false)} className="more-btn l"><i class="fa fa-chevron-left"></i> &nbsp; Return to dashboard</div>

        </div>

        </div>  }
        </> : 
        <>
        <Info currentUser={currentUser} course={chosenCourse} setChosenCourse={setChosenCourse}/>
        </> }
        </div>
        </div>
    )
    }