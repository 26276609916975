import { connect } from 'react-redux';
import { fetchCourseMaterial } from '../../actions/course_actions';
import CourseSection from './course_sections';

const mapStateToProps = (state) => {
  return {
    loggedIn: state.session.isAuthenticated,
    currentUser: state.session.user,
    sections: state.courses.sections,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCourseMaterial: (chapter_id) => dispatch(fetchCourseMaterial(chapter_id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseSection);