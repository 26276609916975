import { connect } from 'react-redux';
import Checkout from './checkout';
import { logout } from '../../actions/session_actions';
import { fetchPaymentIntent } from '../../actions/checkout_actions';
import { signupCourse } from '../../actions/course_actions';

const mapStateToProps = (state) => {
  return {
      client_secret: state.checkout.secret,
      newCourse: state.courses.newCourse
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPaymentIntent: (info) => dispatch(fetchPaymentIntent(info)),
    signupCourse: (user_id, courseData) => dispatch(signupCourse(user_id, courseData))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);