import React, {useState, useEffect } from 'react';
import { Document, Page, pdfjs } from "react-pdf";

import image from '../../assets/header1.png'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function Section(props){

    const {logout, pages, samplePDF} = props
    const [display, setDisplay] = useState(false)

    useEffect(() => {
        window.scrollTo(-20, 0)
    })

    return (
        <>
                    {pages.map(page => (
                    <Page  pageNumber={page} width={window.screen.width} />
                        ))}
        </>
    )
    }