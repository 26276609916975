import { connect } from 'react-redux';
import { fetchAssignments} from '../../actions/course_actions';
import Submissions from './submissions';

const mapStateToProps = (state) => {
  return {
    assignments: state.courses.assignments,
    currentUser: state.session.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    fetchAssignments: (course_id) => dispatch(fetchAssignments(course_id)),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Submissions);