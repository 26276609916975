import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useStripe, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Ring } from 'react-spinners-css';

import CheckoutForm from './checkout_form';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51Hf6jVLTqHc3nTvT5jlI9iwWS2SgWCKDG0ply0fPInvB2UIqOW2ZWbTCma4wPTU97F3iMcWcNXWahMtnYA45h6we00e1dlCOsK');

export default function Checkout(props) {

    const { fetchPaymentIntent, client_secret, history, total, course, cost, currentUser, setCheckout, signupCourse, newCourse, courseID } = props
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        if (!client_secret) {
        fetchPaymentIntent({  
            amount: total,
            currency: 'usd',
            payment_method_types: ['card']}) }
     }, [client_secret])

     useEffect(() => {
        if (newCourse) {
        window.location.reload() }
     }, [newCourse])


  return (
    <div className="dash-main">
    <div className="slide-in-bottom">
    <div onClick={() => setCheckout(false)} className="nav-btn l wht">
            <i class="fa fa-chevron-left"></i> &nbsp; Back
       </div>
    <div className="dash">

            <div className="entry-text-low cos-gradient">Order Summary. </div> 
            <br/>
            <div className="course-name">Once you make your payment, you will have immediate access to the online course material.</div>
            <br/>
            <div className="course-order-header">Student Name</div>
            <div className="course-name">{currentUser.first_name} {currentUser.last_name}</div>
            <br/>
            <div className="course-order-header">Course Name</div>
            <div className="course-name">{course}</div>
            <br/>
            <div className="course-order-header">Cost</div>
            <div className="course-cost">${cost}.00 USD</div>
            <br/>
            <div className="course-order-header">Card Details</div>
                <Elements stripe={stripePromise}>
                    <CheckoutForm courseID={courseID} courseName={course} setProcessing={setProcessing} signupCourse={signupCourse} currentUser={currentUser} intent={client_secret} history={history}/>
                </Elements>
            </div>
    </div>
    <div className={processing ? "overlay" : "hidden"}>
                    <div className="ring">
                        <Ring className="ring" color="#fff" size={30} />
                    </div> 
    </div>
    </div>

  );
};