import React, {useState, useEffect } from 'react';
import UploadS3 from './upload_photo';



export default function Section(props){

    const {logout, worksheets, setSubmissionSection} = props
    const [docURL, setDocURL] = useState(null)
    const [display, setDisplay] = useState(false)
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        window.scrollTo(-20, 0)
    })

    function exit() {
        setSubmissionSection(null)
        setDocURL(null)
        setSubmitted(false)
    }

    function submit() {
        setSubmitted(true)

    }

    return (
        <>
        <div onClick={exit} className="close"><i class="fal fa-times"></i></div>
        <div className={submitted ? "submission-accepted" : "hidden"}>
        Thank you for your submission! A trainer will look over your work and get back to you within 24-48 hours.
        </div>
        <div className={display ? "hidden": ""}>
        <div className={docURL ? "submission-accepted" : "submission-instructions"}>
        {!docURL ? "Please download, print, and complete the worksheets for this section.  When you're finished working on them, come back here to submit them for approval." :
        submitted ? "Thank you for your submission! A trainer will look over your work and get back to you within 24-48 hours." : null}
        </div>
        <a className="download-link" href={worksheets} target = "_blank">
            <i class="fad fa-download"></i> Download Worksheets
        </a>
        <div onClick={() => setDisplay(true)} className="submit-link">
            <i class="fas fa-upload"></i> Submit My Work
        </div>
        </div>
        <div className={display ? "" : "hidden"}>

        {docURL ? 
        <>
        <img className="submitted-image" src={docURL}></img> 
        {submitted ? null : <>
        <div onClick={exit} className="submit-cancel">
            Exit
        </div>
        <div onClick={submit} className="submit-ok">
            Submit
        </div>
        </>
        }
        </> : 
        <>
        <div className="submission-instructions">
            You may add an image or pdf file to submit your work.
        </div>
        <UploadS3 setURL={setDocURL}/>
        </>
        
        }
        </div>
        </>
    )
    }