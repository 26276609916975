import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Footer from './footer';
import Logo from '../../assets/cosmetinkLogo.png'
import CourseNav from './course_nav_container';

export default function CourseInfo(props) {

    const {} = props    
    const [profile, setProfile] = useState(null)

    function openProfile() {
        profile ? setProfile(null) : setProfile(true)
    }
  


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <div className="PMU-details">
            <CourseNav />
            <Footer selected={2} />
        <div className="PMU-detail-page">
        <Link to="/dashboard" className="back-to-home wht">
        <img className="course-logo" src={Logo}></img>
        </Link>

        <div onClick={openProfile} className="hamburger"><a><i class="fal fa-user"></i></a></div>
        <div className={profile ? "profile" : "hidden"}>
        <i class="fal fa-sign-out"></i> Sign Out
        </div>
        <div className="slide-in-bottom">
        <div className="p700">
        <h1 className="entry-text-o lower-case c">
             In-Person Course Information
         </h1>
        <div className="wht pad-20">
        Dates:<br/>Saturday, June 26th 2021 & Sunday, June 27th 2021 <br/><br/>
                           Times:<br/> 9am - 6pm each day <br/><br/>
                           Location:<br/> Holiday Inn Express <br/>
                           Parlor Room <br/><br/>
                           90 Kunkle Drive <br/>
                           Easton, PA 18045 <br/>
                           <a className="more-btn l p" href="callto:6109230511">(610) 923-0511</a> <br/>
                           <a href="https://www.google.com/maps/dir/40.7437312,-73.97376/holiday+inn+express+kunkle+drive+easton+pa/@40.7797285,-75.1754154,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x89c46b7bf0169719:0x3530e08e52be47b3!2m2!1d-75.256291!2d40.683514">
                                <div className="more-btn l">Get Directions <i class="fa fa-chevron-right"></i></div>
                            </a>
                           <br/>
                           Please be ON TIME for each day's class.  Remember, you are responsible for finding a model to work on for your second day of class.  We will confirm with you by email as to what time to tell your model to come.
                           <br/><br/>

                           Intructors:<br/>Nicolette Altemose & Sean Altemose
                            </div>
        </div>
        </div>
        </div>
        </div>
        </>
    )
}