import { connect } from 'react-redux';
import Frontdesk from './frontdesk';
import { fetchAppointments, fetchAppointments2, fetchClients, fetchClientAppts } from '../../actions/user_actions'
import { fetchTasks, postTask, deleteTask } from '../../actions/task_actions';

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.user,
    dailyAppointments: state.user.appointments,
    monthlyAppointments: state.user.monthlyAppointments,
    clients: state.user.clients,
    clientAppts: state.user.clientAppts,
    tasks: state.tasks.tasks,
    newTask: state.tasks.newTask,
    deletedTask: state.tasks.deletedTask
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
    fetchAppointments: (calendarID, minDate, maxDate) => dispatch(fetchAppointments(calendarID, minDate, maxDate)),
    fetchAppointments2: (calendarID, minDate, maxDate) => dispatch(fetchAppointments2(calendarID, minDate, maxDate)),
    fetchTasks: () => dispatch(fetchTasks()),
    postTask: (taskBody) => dispatch(postTask(taskBody)),
    deleteTask: (task_id) => dispatch(deleteTask(task_id)),
    fetchClients: (client) => dispatch(fetchClients(client)),
    fetchClientAppts: (firstName, lastName) => dispatch(fetchClientAppts(firstName, lastName))
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Frontdesk);