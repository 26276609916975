import { connect } from 'react-redux';
import Tasks from './tasks';

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.user,

  };
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tasks);