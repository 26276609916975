import * as Util from '../util/task_api_util'

export const RECEIVE_TASKS = "RECEIVE_TASKS";
export const RECEIVE_NEW_TASK = "RECEIVE_NEW_TASK";
export const RECEIVE_DELETED_TASK = "RECEIVE_DELETED_TASK";

export const receiveTasks = tasks => ({
    type: RECEIVE_TASKS,
    tasks
})

export const receiveNewTask = newTask => ({
    type: RECEIVE_NEW_TASK,
    newTask
})

export const receiveDeletedTask = deletedTask => ({
    type: RECEIVE_DELETED_TASK,
    deletedTask
})




export const fetchTasks = () => dispatch => {
    Util.fetchTasks().then(tasks => {
        dispatch(receiveTasks(tasks))
    })
}

export const postTask = (taskBody) => dispatch => {
    Util.postTask(taskBody)
    .then(newTask => {
        dispatch(receiveNewTask(newTask))
    })
}

export const deleteTask = (task_id) => dispatch => {
    Util.deleteTask(task_id)
    .then(resp => {
        dispatch(receiveDeletedTask(resp))
    })
}