import * as APIUtil from '../util/session_api_util';
import * as UserAPIUtil from '../util/users_util';

export const RECEIVE_USER_INFO = "RECEIVE_USER_INFO";
export const RECEIVE_CLIENTS = "RECEIVE_CLIENTS";
export const RECEIVE_CLIENT_APPTS = "RECEIVE_CLIENT_APPTS";
export const RECEIVE_ARTIST_INFO = "RECEIVE_ARTIST_INFO";
export const RECEIVE_UPDATED_USER_INFO = "RECEIVE_UPDATED_USER_INFO";
export const RECEIVE_APPOINTMENTS = "RECEIVE_APPOINTMENTS";
export const RECEIVE_AVAILABILITY = "RECEIVE_AVAILABILITY";
export const RECEIVE_AVAILABLE_TIMES = "RECEIVE_AVAILABLE_TIMES";
export const RECEIVE_NEW_APPOINTMENT = "RECEIVE_NEW_APPOINTMENT";
export const RECEIVE_MONTHLY_APPOINTMENTS = "RECEIVE_MONTHLY_APPOINTMENTS";
export const RECEIVE_MONTHLY_APPOINTMENTS2 = "RECEIVE_MONTHLY_APPOINTMENTS2";

export const receiveUserInfo = user => ({
    type: RECEIVE_USER_INFO,
    user
})

export const receiveClients = clients => ({
    type: RECEIVE_CLIENTS,
    clients
})

export const receiveClientAppts = clientAppts => ({
    type: RECEIVE_CLIENT_APPTS,
    clientAppts
})

export const receiveArtistInfo = artist => ({
    type: RECEIVE_ARTIST_INFO,
    artist
})


export const receiveUpdatedUserInfo = updated => ({
    type: RECEIVE_UPDATED_USER_INFO,
    updated
})

export const receiveAppointments = appointments => ({
    type: RECEIVE_APPOINTMENTS,
    appointments
})

export const receiveMonthlyAppointments = monthlyAppointments => ({
    type: RECEIVE_MONTHLY_APPOINTMENTS,
    monthlyAppointments
})

export const receiveMonthlyAppointments2 = monthlyAppointments2 => ({
    type: RECEIVE_MONTHLY_APPOINTMENTS2,
    monthlyAppointments2
})

export const receiveAvailability = availability => ({
    type: RECEIVE_AVAILABILITY,
    availability
})

export const receiveAvailableTimes = times => ({
    type: RECEIVE_AVAILABLE_TIMES,
    times
})

export const receiveNewAppointment = res => ({
    type: RECEIVE_NEW_APPOINTMENT,
    res
})

export const fetchUser = (id) => dispatch => {
    APIUtil.fetchUser(id).then(user => {
        dispatch(receiveUserInfo(user))
    })
}

export const fetchArtist = (artist) => dispatch => {
    APIUtil.fetchArtist(artist).then(artist => {
        dispatch(receiveArtistInfo(artist))
    })
}

export const updateUserMicrobladingSessionsCompleted = (id, update) => dispatch => {
    UserAPIUtil.updateUserMicrobladingSessionsCompleted(id, update).then(user => {
        dispatch(receiveUpdatedUserInfo(user))
    })
}

export const fetchAppointments = (calendarID, minDate, maxDate) => dispatch => {
    UserAPIUtil.fetchAppointments(calendarID, minDate, maxDate)
    .then(appointments => {
        dispatch(receiveAppointments(appointments))
    })
}

export const fetchAppointments2 = (calendarID, minDate, maxDate) => dispatch => {
    UserAPIUtil.fetchAppointments2(calendarID, minDate, maxDate)
    .then(monthlyAppointments => {
        dispatch(receiveMonthlyAppointments(monthlyAppointments))
    })
}

export const fetchAppointments3 = (calendarID, minDate, maxDate) => dispatch => {
    UserAPIUtil.fetchAppointments2(calendarID, minDate, maxDate)
    .then(monthlyAppointments2 => {
        dispatch(receiveMonthlyAppointments2(monthlyAppointments2))
    })
}

export const fetchAvailableDates = (calendarID, apptid, month) => dispatch => {
    UserAPIUtil.fetchAvailableDates(calendarID, apptid, month)
    .then(availability => {
        dispatch(receiveAvailability(availability))
    })
}

export const fetchAvailableTimes = (calendarID, apptid, date) => dispatch => {
    UserAPIUtil.fetchAvailableTimes(calendarID, apptid, date)
    .then(times => {
        dispatch(receiveAvailableTimes(times))
    })
}

export const fetchClients = (client) => dispatch => {
    UserAPIUtil.fetchClients(client)
    .then(clients => {
        dispatch(receiveClients(clients))
    })
}

export const fetchClientAppts = (firstName, lastName) => dispatch => {
    UserAPIUtil.fetchClientAppts(firstName, lastName)
    .then(clientAppts => {
        dispatch(receiveClientAppts(clientAppts))
    })
}

export const postAppointment = (apptDetails) => dispatch => {
    UserAPIUtil.postAppointment(apptDetails)
    .then(res => {
        dispatch(receiveNewAppointment(res))
    })
}

