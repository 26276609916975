import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Microblading from '../../assets/microheading.png'
import Checkout from '../payments/payment_container';
import CustomerInfo from '../customer/customer_info_container';

export default function Info(props){

    const { course, setChosenCourse, currentUser } = props

    const [checkout, setCheckout] = useState(false)
    const [customerInfo, setCustomerInfo] = useState(false)

    useEffect(() => {

    }, [])


    return (
        <div className="slide-in-right">
        <div className="dash-main">

        {!checkout ? 
        <>
        <div onClick={() => setChosenCourse(null)} className="nav-btn l wht">
            <i class="fa fa-chevron-left"></i> &nbsp; Back
       </div>
        <div className="dash inside-scroll">
       <img src={Microblading}></img>
       <br/>
       <div className="course-order-header">
           Cost
       </div>
       <div className="course-cost">
           ${course.cost}.00
       </div>
       <br/><br/><br/>
       <div onClick={() => setCheckout(true)} className="schedule-btn"><div className="schedule-inside">Register Now</div></div>
       <br/>
       <div className="course-order-header">
           Description
       </div>
       <div className="course-name">
           {course.info}
       </div>
       <br/>


      

        </div>
        </> :
        <>
        <Checkout currentUser={currentUser} courseID={course._id} course={course.name} cost={course.cost} total={course.cost * 100} setCheckout={setCheckout}/>
        </>}
        </div>
        </div>
    )
    }