import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Footer from './footer'
import Logo from '../../assets/cosmetinkLogo.png'
import CourseNav from './course_nav_container';

export default function Contact(props) {



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <>
        <div className="PMU-details wht-back">
        <CourseNav color={true}/>
        <Footer selected={5} />
        <div className="PMU-detail-page wht-back">
        <div className="slide-in-bottom">
        <div className="p700">
        <div className="white-back">
 
        <div className="entry-text-p">Contact Us</div>
        <div className="pad-20 font">
        Please reach out to us at <a href="mailto:cosmetinkbeauty@gmail.com">cosmetinkbeauty@gmail.com</a> if you have any questions pertaining to the course or regarding your assignments.
        <br/><br/>
        We will get back to you within 24 hours.
        </div>
       
        </div>
        </div>
        </div>
        </div>
        </div>
        </>
    )
}