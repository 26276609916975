import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Footer from './footer';
import CourseNav from './course_nav_container';
import { Ring, Line, Heart } from 'react-spinners-css';

export default function BookingPage(props) {

    const {currentUser, fetchAvailableDates, availability, 
        fetchAvailableTimes, times, client, closeTouchup, postAppointment, newAppointment,
        fetchAppointments3, monthlyAppointments2, fetchArtist, artist} = props

    const [result, setResult] = useState(null)

    const [dateObject, setDateObject] = useState(moment())
    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)
    const [currentDay, setCurrentDay] = useState(null)
    const [selectedFullDate, setSelectedFullDate] = useState(null)
    const [dateArray, setDateArray] = useState(null)
    const [monthDigit, setMonthDigit] = useState(null)
    const [minMaxDate, setMinMaxDate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [writtenDate, setWrittenDate] = useState(null)
    const [writtenMonth, setWrittenMonth] = useState(null)
    const [yearsOpen, setYearsOpen] = useState(false)
    const [monthsOpen, setMonthsOpen] = useState(false)
    const [availabilityArray, setAvailabilityArray] = useState([])
    const [TUDate, setTUDate] = useState(null)
    const [timesArray, setTimesArray] = useState([])
    const [selectedTime, setSelectedTime] = useState(null)
    const [datetime, setDatetime] = useState(null)
    const [appt, setAppt] = useState({})
    const [confirmed, setConfirmed] = useState(false)
    const [selectedApptID, setSelectedApptID] = useState(false)
    const [notes, setNotes] = useState(null)
    const [yearTime, setYearTime] = useState(null)
    const [datesList, setDatesList] = useState(null)
    const [selectArtist, setSelectedArtist] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [sundays, setSundays] = useState([])
    const [isUpdated, setIsUpdated] = useState(false)


    function getAppointments2() {
      fetchAppointments3(client.calendarID, `${yearTime}`+`-`+`${monthDigit}`+`-`+`${1}`, `${yearTime}`+`-`+`${monthDigit}`+`-`+`${31}`)
        setIsUpdated(true)

    
  }

  function fetchSelectArtist(client) {

      fetchArtist(client.calendarID)
    
  }


  function setSelectRow(key) {

    setSelectedRow(key)
  
}

  useEffect(() => {
    if (client && !selectArtist) {
      fetchSelectArtist(client)
    }
    
 }, [client, selectArtist])



 useEffect(() => {
  if (availabilityArray) {
   }
  
}, [availabilityArray])

 useEffect(() => {
  if (monthDigit || yearTime) {
    }
}, [yearTime, monthDigit])



    
    function createAppointment() {
        const apptDetails = {
        dt: datetime,
        appointmentTypeID: selectedApptID,
        firstName: client.firstName,
        lastName: client.lastName,
        email: client.email,
        calendarID: client.calendarID,
        phone: client.phone,
        note: notes}

        postAppointment(apptDetails)
        
    }

    function setAppointmentDateTime() {
        setDatetime(`${year}-${monthDigit}-${TUDate}T${convertTime12to24(selectedTime)}`)
    }

    const convertTime12to24 = (time) => {
        var PM = time.match('P.M.') ? true : false
        
        time = time.split(':')
   
        
        if (PM) {
            var hour = 12 + parseInt(time[0],10)
            var min = time[1].replace(' P.M.', '')
        } else {
            var hour = time[0]
            var min = time[1].replace(' A.M.', '')       
        }
        
        return hour + ':' + min
    }

    const date = new Date()
    const today = `${date.getFullYear()}`+`-`+`${date.getMonth() + 1}`+`-`+`${date.getDate()}`

    const months = [`Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`]
    const monthsFull = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const thisMonth = monthsFull[date.getMonth()]
    
    let weekdayshort = moment.weekdaysShort();
    let weekdayshortname = weekdayshort.map(day => {
        return (
          <th key={day} className="week-day">
           {day}
          </th>
        );
     });

   function getAvailability() {
       const apptid = selectedApptID
       const month = `${yearTime}-${monthDigit}`
       
   
 
   

 
      getAppointments2()
      fetchAvailableDates(client.calendarID, apptid, month)

   
       
    
       
       
 
      
   
      
    }

    function getTimes() {
        const apptid = selectedApptID
        const date = `${year}-${monthDigit}-${TUDate}`
        fetchAvailableTimes(client.calendarID, apptid, date)
    }

    function createAvailableArray() {
        const availableArray = []
        Object.keys(availability).map((key) => {
            let day = availability[key].date
            day = day.split("-")
            availableArray.push(day[2].replace(/^0+/, ''))})
        setAvailabilityArray(availableArray)
    }

    useEffect(() => {
        if (selectedApptID) {
            if (monthDigit) {
         getAvailability() }
        }
        if (monthDigit && !selectedApptID) {
           if (selectedApptID && !yearTime)
            {getAvailability() }
        }
        
     }, [selectedApptID, monthDigit, yearTime])

     useEffect(() => {
        if (availability) {
         createAvailableArray()
        }
     }, [availability])

     useEffect(() => {
       if (selectedFullDate) {
        setDateArray(selectedFullDate.split(", ")) 
       }
    }, [selectedFullDate])

    useEffect(() => {
        if (!currentDay) {
          setSelectedDate(date.getDate())
        }
     }, [currentDay])

    useEffect(() => {
        if (dateArray) {
   
        const m = dateArray[1].split(" ")[0]
        const y = dateArray[2].split(" ")[0]
         setMonth(m)
         setYear(y)
        }
     }, [dateArray])

     useEffect(() => {
        window.scrollTo(0, 0)
     }, [])

     useEffect(() => {
        if (newAppointment) {
            setConfirmed(true)
            setTimeout(() => {
                window.location.reload()
            }, 200);
           
        }
     }, [newAppointment])


     useEffect(() => {
        if (month) {
        const md = months.indexOf(month)
         setMonthDigit(md +1)
         setYear(year)
      
        }
     }, [month])

     useEffect(() => {
        if (year) {
            setYearTime(year)
        }
     }, [year])

     useEffect(() => {
        if (selectedTime) {
        setAppointmentDateTime()
        }
     }, [selectedTime])

     useEffect(() => {
        if (TUDate) {
        getTimes() }
     }, [TUDate])

    useEffect(() => {
       if (times) {
        const arr = []
        Object.keys(times).map((key) => {
            var time = times[key].time.split("T")
            arr.push(setStandardTime(time[1].split("").slice(0,5).join("")))
        })
        setTimesArray(arr)
        }
     }, [times])





    useEffect(() => {
        if (year || month || currentDay) {
            setDateObject(moment().set({'date': currentDay, 'month': month, 'year': year}))
            setSelectedFullDate(moment().set({'date': currentDay, 'month': month, 'year': year}).format("llll"))
            setWrittenDate(`${monthsFull[months.indexOf(month)]}` + ` ` + `${year}`)
            setWrittenMonth(`${monthsFull[months.indexOf(month)]}`)
        }
    }, [year, month, currentDay])

    function setStandardTime(time) {
            let timeValue
            //var time = "12:23:39";
            var time = time.split(':');
            var hours = time[0];
            var minutes = time[1];
            timeValue = "" + ((hours >12) ? hours -12 :hours);
            timeValue += (minutes < 10) ? ":00" : ":" + minutes;
            timeValue += (hours > 11) ? " P.M." : " A.M.";
            return timeValue
            
    }

    function setSelectedYear(data) {
        if (year !== data) {
            setAvailabilityArray([])
            setYear(data) 
            setResult(null)
             }
        
    }

    function setSelectedMonth(data) {
       if (writtenMonth !== data) {
        setAvailabilityArray([])
           setMonth(data)
        setResult(null)
         }
    }

    function setSelectedDate(d) {
        setCurrentDay(d)
        setResult(null)
       
    }

    function setSelectedTUDate(d) {
        setTUDate(d)
        setResult(null)
    }

    function setSundayArray(sundayArray) {
      setSundays(sundayArray)
  }

    function firstDayOfMonth() {
        return dateObject.startOf("month").format("d")
    }

        let blanks = [];
        
        for (let i = 0; i < firstDayOfMonth(); i++) {
          blanks.push(
            <td className="calendar-day empty">{""}</td>
          );
        }

        let daysInMonth = [];

        for (let d = 1; d <= dateObject.daysInMonth(); d++){ 
            let currentday = d == currentDay ? "today" : d == TUDate && availabilityArray.includes(d.toString()) ? "tudate" : ""; 
                daysInMonth.push(  
                   <td key={d} onClick={() => setSelectedTUDate(d)} className={availabilityArray.includes(d.toString()) ? `calendar-day2 ${currentday} available` : `calendar-day2 ${currentday} not-available`}>   
                    {d} <br/> 
                    
                    
                   
                        
                     
                        
                  
                
                   </td>);
         }
         
         let daysInMonth2 = [];

        for (let d = 1; d <= dateObject.daysInMonth(); d++){ 
            let currentday = d == currentDay ? "today" : d == TUDate && availabilityArray.includes(d.toString()) ? "tudate" : ""; 
                daysInMonth2.push(  
                   <td key={d} className={availabilityArray.includes(d.toString()) ? `calendar-day3 ${currentday} available` : `calendar-day3 ${currentday} not-available`}>   
                    {d} <br/> 
                    
                    
                   
                        
                     
                    {
                      (monthlyAppointments2 && selectedApptID && isUpdated) ? Object.keys(monthlyAppointments2).reverse().map((key) => {
                        console.log(parseInt(monthlyAppointments2[key].date.split(" ")[1]))
                        if (monthlyAppointments2[key].date.split(" ")[0].includes(month) && parseInt(monthlyAppointments2[key].date.split(" ")[1]) === d)
                        return ( 
                          <div className={monthlyAppointments2[key].type.includes("PACKAGE") ? `appointment-box3 package box-${monthlyAppointments2[key].duration} appointment2-${monthlyAppointments2[key].time.split(":").join("")}` : monthlyAppointments2[key].type.includes("Brows") ? `appointment-box3 brows box-${monthlyAppointments2[key].duration} appointment2-${monthlyAppointments2[key].time.split(":").join("")}` : monthlyAppointments2[key].type.includes("Lips") ? `appointment-box3 lips box-${monthlyAppointments2[key].duration} appointment2-${monthlyAppointments2[key].time.split(":").join("")}` : monthlyAppointments2[key].category.includes("STUDENTS") ? `appointment-box3 students box-${monthlyAppointments2[key].duration} appointment2-${monthlyAppointments2[key].time.split(":").join("")}` : monthlyAppointments2[key].type.includes("Lash") ? `appointment-box3 eyeliner box-${monthlyAppointments2[key].duration} appointment2-${monthlyAppointments2[key].time.split(":").join("")}` : `appointment-box3 other box-${monthlyAppointments2[key].duration} appointment2-${monthlyAppointments2[key].time.split(":")[0] + monthlyAppointments2[key].time.split(":")[1]}`}>
                         
                         {monthlyAppointments2[key].time} - {monthlyAppointments2[key].endTime} <br/>
                         {monthlyAppointments2[key].type} <br/><br/>
                         {monthlyAppointments2[key].firstName + ' ' + monthlyAppointments2[key].lastName } <br/><br/>
                         </div> ) 
                      })
                      
                    : <Heart className="ring" color="#fff" size={30} />}

                  
                
                   </td>);
         }

         var totalSlots = [...blanks, ...daysInMonth];
         var totalSlots2 = [...blanks, ...daysInMonth2];
         let rows = [];
         let rows2 = [];
         let cells = [];
         let cells2 = [];
       
         totalSlots.forEach((row, i) => {
             if (i % 7 !== 0) {
               cells.push(row); // if index not equal 7 that means not go to next week
             } else {
               rows.push(cells); // when reach next week we contain all td in last week to rows 
               cells = []; // empty container 
               cells.push(row); // in current loop we still push current row to new container
             }
             if (i === totalSlots.length - 1) { // when end loop we add remain date
               rows.push(cells);
             }
           });

           totalSlots2.forEach((row, i) => {
            if (i % 7 !== 0) {
              cells2.push(row); // if index not equal 7 that means not go to next week
            } else {
              rows2.push(cells2); // when reach next week we contain all td in last week to rows 
              cells2 = []; // empty container 
              cells2.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots2.length - 1) { // when end loop we add remain date
              rows2.push(cells2);
            }
          });

          let calendar 
          let calendar2
 
          if (isUpdated) {
            calendar = rows.map((d, i) => {
             return <tr>{d}</tr>;
           });

            calendar2 = rows2.map((d, i) => {
            return <tr key={i}>{d}</tr>;
          });
        }



    
    let allmonths = moment.months()

    function MonthList() {
        

        let months = [];
        allmonths.map(data => {
            {
          months.push(
            <td 
            key={data} 
            className={writtenMonth == data ? "calendar-month today" : "calendar-month"} onClick={() => setSelectedMonth(data)}>
            {data}
            </td>
          ) };
        });
  
      let monthrows = [];
      let monthcells = [];
  
      months.forEach((monthrow, i) => { 
          if (i % 3 !== 0 || i == 0) { // except zero index 
              monthcells.push(monthrow); 
          } else { 
              monthrows.push(monthcells); 
              monthcells = [];
              monthcells.push(monthrow); 
          }
       });
       monthrows.push(monthcells); // add last row
  
       let monthlist = monthrows.map((r, i) => {
          return <tr className="cal-month-list">
                {Object.keys(r).map((m) => {
                    return (
                    <td className="calendar-month">{r[m]}</td>
                    )
                })}
                </tr>;
       });

       return <div className="abs-list">{monthlist}</div>
    }

    function YearList() {
        let months = [];
        let nextOne = moment()
          .set("year", props)
          .add("year", 2)
          .format("Y");

          function getDates(startDate, stopDate) {
            var dateArray = [];
            var currentDate = moment(startDate);
            var stopDate = moment(stopDate);
            while (currentDate <= stopDate) {
              dateArray.push(moment(currentDate).format("YYYY"));
              currentDate = moment(currentDate).add(1, "year");
            }
            return dateArray;
          }

          let twelveyears = getDates(props, nextOne);

          twelveyears.map(data => {
            months.push(
              <td
                key={data}
                className={year === data ? "calendar-month-2 today" : "calendar-month-2" }
                onClick={() => {
                  setSelectedYear(data);
                }}
              >
                {data}
              </td>
            );
          });
          let rows = [];
          let cells = [];
      
          months.forEach((row, i) => {
            if (i % 3 !== 0 || i == 0) {
              cells.push(row);
            } else {
              rows.push(cells);
              cells = [];
              cells.push(row);
            }
          });
          rows.push(cells);
          let yearlist = rows.map((d, i) => {
            return <tr className="cal-month-list">
                    <td className="calendar-month-2">{d}</td></tr>;
          });

          return <div className="abs-list-2">{yearlist}</div>;
      
        
    }

    function setMonths() {
        monthsOpen ? setMonthsOpen(false) : setMonthsOpen(true)
        setIsUpdated(false)
    }

    function setYears() {
        yearsOpen ? setYearsOpen(false) : setYearsOpen(true)
        setIsUpdated(false)
    }

    function clearTU() {
        if (confirmed) {
            window.location.reload()
        } else {
        setTUDate(null)
        setTimesArray([])
        setSelectedTime(null)
        setDatetime(null)
        setSelectedApptID(null)
        }
    }

    return (
       <div className="booking-page-full slide-in-bottom">
        <div onClick={closeTouchup} className="scheduled-appt-title wht abs-nav">close</div>
       


        <div className='left-half'>
        {selectedApptID && !availability? <><div className="full-blur"> 
          <Ring className="ring abs-s" color="#fff" size={30} /></div></> : null }
                <div className="scheduled-appt-title wht c">
                Currently Booking Touch Up for <br/> <br/>
                <div className="moving-gradient">{client.firstName + ` ` + client.lastName}</div>
                
                </div>

        <div className='booking-page-inside'>

        {selectedApptID ? 
        <>
        <div className=" c">

         
        
                <div className="year-list">
                <div className="scheduled-appt-title wht">
                {year && thisMonth ?
                <>
                <div onClick={setMonths} className="inline">{monthsOpen ? MonthList() : <div className="abs-list"><td className="calendar-month today">{writtenMonth}&nbsp;&nbsp;<i class="fas fa-sort-down"></i></td></div>}</div>
                <div onClick={setYears} className="inline">{yearsOpen ? YearList() : <div className="abs-list-2"><td className="calendar-month-2 today">{year}&nbsp;&nbsp;<i class="fas fa-sort-down"></i></td></div>}</div>
                </> : null }
                </div>
                </div> 
        <div>
     
        </div>
        <br/>
        <br/>
        <table className="calendar-table wht">
            <thead>
              <tr>{weekdayshortname}</tr>
            </thead>
            <tbody>
                {availabilityArray.length < 1 ?
                <div className="abs-cal">
                <div className="scheduled-appt-title wht c">
              
                </div>
                </div> : null}
                {calendar}
            </tbody>
          </table>
            
          <br/>
          <br/>
       </div>
       </> : null }
       <br/>
       {artist ?
       <>
        {!selectedApptID ?
        <>
     
       <div className="scheduled-appt-title gry c">
                    Choose An Appointment Type                
        </div>
        <>
   
        { artist.powderbrowTUID ? 
        <div onClick={() => setSelectedApptID(artist.powderbrowTUID)} className={selectedApptID == artist.powderbrowTUID ? "scheduled-appt-title wht c moving-gradient" : "scheduled-appt-title wht c"}>
                Brow Perfecting Session
        </div> : null }

        {artist.lipblushTUID ? 
        <div onClick={() => setSelectedApptID(artist.lipblushTUID)} className={selectedApptID == artist.lipblushTUID ? "scheduled-appt-title wht c moving-gradient" : "scheduled-appt-title wht c"}>
                Lip Blush Perfecting Session
        </div> : null }
        {artist.eyelinerTUID ? 
        <div onClick={() => setSelectedApptID(artist.eyelinerTUID)} className={selectedApptID == artist.eyelinerTUID ? "scheduled-appt-title wht c moving-gradient" : "scheduled-appt-title wht c"}>
                Eyeliner Perfecting Session
        </div> : null }
        </> </>: null } </> : <Ring className="ring abs-s" color="#fff" size={30} />}

    
       {(TUDate && timesArray.length > 0 )? 
       
       <div className="">
        { !confirmed ?
                <>
                <div className="scheduled-appt-title moving-gradient c">
                {`${writtenMonth}` + ` ` + `${TUDate}` +`, ` + `${year}`}
                </div>
                <div className="scheduled-appt-title gry c">
                    Choose A Time
                </div>
                {timesArray.map((time) => {
               return (
                <>
               <div onClick={() => setSelectedTime(time)} className={selectedTime == time ? "scheduled-appt-title wht c moving-gradient" : "scheduled-appt-title wht c"}>
                {time}
                </div> 
                </>
                )
           })
           }
                {selectedTime ? 
                <div className='abs-list-4'>
                <textarea placeholder={`Add notes here...`} onChange={(e) => setNotes(e.currentTarget.value)} className="notes-input">

                </textarea>
                <div onClick={() => createAppointment()} className="booking-link">
                Confirm and Book
                </div> </div>:<div className="booking-link-2">Confirm and Book</div>}
                </> : <><div className="scheduled-appt-title moving-gradient c">Confirmed!</div>
                        </>}
       </div> : <div className="scheduled-appt-title wht c">
     
       </div>}
       </div>
       
  
       
       </div>
       {(selectedApptID && isUpdated) ? 
       <div className='right-half'>
       <div className='right-half-table'>
       <div className="scheduled-appt-title wht c">
            {writtenMonth} {year}
            </div>
            <div className='right-side'>
       <table className="calendar-table wht">
            <thead>       
          <tr>{weekdayshortname}</tr>
            </thead>

           
            <tbody>
                {availabilityArray.length < 1 ?
                <div className="abs-cal">
                <div className="scheduled-appt-title wht c">
            
                </div>
                </div> : null}
                {calendar2}
            </tbody>
    
          </table>
          </div>
       </div>
       </div> : null }
       </div>

    
       
    )
}