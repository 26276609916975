import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

import Logo from '../../assets/cosmetinkLogo.png'
import SearchBar from './searchbar';


export default function CourseNav(props){

    const {logout, currentUser, fetchUser, color, fetchClients, clients, fetchClientAppts, clientAppts
       } = props
    const [display, setDisplay] = useState(null)

    const [profile, setProfile] = useState(null)

    function openProfile() {
        profile ? setProfile(null) : setProfile(true)
    }



    function openPage(key) {
        if (key) {
        setDisplay(key)
        } else {
            setDisplay(null)
        }
    }

    return (
        

        <div className="course-nav">
        <Link className='inventory-link' to="/inventory">Inventory</Link>
        <SearchBar fetchClients={fetchClients} clients={clients} fetchClientAppts={fetchClientAppts}
            clientAppts={clientAppts}/>
        <div onClick={openProfile} className="hamburger"><a><i class="fas fa-user"></i></a></div>
        <div className={profile ? color ? "profile-p" : "profile" : "hidden"}>
        <div className="scheduled-appt-title moving-gradient w-100"> 
            {currentUser ? currentUser.first_name + ' ' + currentUser.last_name : null}
        </div>
        <br/>
        <div className="scheduled-appt-title w-100" onClick={logout}>
        <i  class="fal fa-sign-out"></i>&nbsp; Sign Out
        </div>
        </div>
        <Link to='/dashboard'><img className="course-logo" src={Logo}></img></Link>
        <div className="PMU-detail-page c">
       </div></div>

        
    )
    }