import { connect } from 'react-redux';
import { logout } from '../../actions/session_actions';
import { fetchUser, updateUserMicrobladingSessionsCompleted } from '../../actions/user_actions';
import { fetchCourseMaterial, fetchChapters } from '../../actions/course_actions';
import TestCourse from './test_course';

const mapStateToProps = (state) => {
  return {
    loggedIn: state.session.isAuthenticated,
    currentUser: state.session.user,
    user: state.user.user,
    updated: state.user.updated,
    sections: state.courses.sections,
    chapters: state.courses.chapters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (id) => dispatch(fetchUser(id)),
    logout: user => dispatch(logout(user)),
    fetchCourseMaterial: (course_id) => dispatch(fetchCourseMaterial(course_id)),
    fetchChapters: (course_id) => dispatch(fetchChapters(course_id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestCourse);